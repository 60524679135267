<template>
<div>
<header-component>
</header-component>
<loader-component v-if="loading"></loader-component>
    <div class="container-fluid" style="background-color:#E7E7E7">.</div>
    <div class="container-fluid " style="background-color:#F6F6F6">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-5   mt-1">
          <h3 class="Login-heading">Log In</h3>
          <div class="card mb-5 border-0">
            <div class="card-header border-0" style="background-color:#eee;">
              <h2 class="login-form-title">Only single user Log In allowed at a time.</h2>
            </div>
            <div class="card-body">
              <span v-if="error" style="font-size: 14px;font-weight: 200; color:#FF0000">
                {{ error_msg }}
              </span>
              <div class="row mt-5">
                <div class="col-md-3 pt-2 mb-3">
                  <label style="color: grey; font-family: Lato; margin-left: 20px;">Username</label>
                </div>
                <div class="col-md-8">
                  <input type="text" style="color: grey; font-family: Calibri;" class="form-control border-0 border-bottom rounded-0" v-model="username"
                    placeholder="Enter Username"
                    v-bind:class="{ 'border-success': isusernameFocused, 'border-danger': isusernameEmpty }"
                    @click="usernameClick()" v-on:blur="usernameClickout()">
                  <span v-if="isusernameEmpty" style="font-size: 14px; font-weight: 200; color:#FF0000">Username is
                    required</span>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-md-3 pt-2 mb-3">
                  <label style="color: grey; font-family: Lato; margin-left: 20px;">Password</label>
                </div>
                <div class="col-md-8">
                  <input type="password" style="color: grey;" class="form-control border-0 border-bottom rounded-0" v-model="password"
                    placeholder="Enter Password"
                    v-bind:class="{ 'border-success': isPasswordFocused, 'border-danger': isPasswordEmpty }"
                    @click="passwordClick()" v-on:blur="passwordClickout()">
                  <span v-if="isPasswordEmpty" style="font-size: 14px;font-weight: 200; color:#FF0000">Password is
                    required</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-3 mt-4 form-inline d-flex gap-1">
                  <input type="submit" style="font-size: small;" name="" value="    LOG IN    " class="btn btns btn-md" @click="login()">
                  <input v-if="logout_btn" style="font-size: small;" type="submit" name="" value="LOG OUT"  class="btn btns btn-md" @click="UserLogoutStep()">
                </div>
              </div>
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-9 mt-2">
                  <input type="submit" style="font-size: small;" name="" value="      FORGOT PASSWORD      " class="btn btns btn-md">
                  <!-- <button type="submit" name="" class="btn btns btn-sm"><span class="text-center text-uppercase">forgot 
                       password</span></button> -->
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="col-md-3"></div>
      </div>
    </div>
    <div class="container-fluid" style="background-color:#D1D3D4">
      <div class="container">
        <div class="row">

          <div class="col-md-3 offset-md-2">
            <h3 class="mt-3"
              style="font-size: 20px; font-family: Arvo !important; color: #58585b; font-weight:700;vertical-align: middle !important;">
              Partners</h3>
          </div>
          <div class="col-md-3">
            <img src="img/bank.png" class="img img-fluid mt-3 mb-3">
          </div>
        </div>
      </div>
    </div>

  <footer-component></footer-component>
  </div>
</template>
<script>

export default {
  data() {
    return {
      isusernameFocused: false,
      isusernameEmpty: false,
      username: '',
      isPasswordFocused: false,
      isPasswordEmpty: false,
      password: '',
      error: false,
      error_msg:'',
      logout_btn:false,
      loading: false
    }
  },
  methods: {
    passwordClick() {
      this.isPasswordFocused = true
    },
    passwordClickout() {
      if (this.password.trim() === '') {
        this.isPasswordFocused = false
        this.isPasswordEmpty = true
      }
      else {
        this.isPasswordEmpty = false
      }
    },
    usernameClick() {
      this.isusernameFocused = true
    },
    usernameClickout() {
      if (this.username.trim() === '') {
        this.isusernameFocused = false
        this.isusernameEmpty = true
      }
      else {
        this.isusernameEmpty = false
      }
    },
    async login() {
      if ((this.username == '') && (this.password == '')) {
        this.isusernameFocused = false
        this.isusernameEmpty = true
        this.isPasswordFocused = false
        this.isPasswordEmpty = true
      }
      else if (this.username == '') {
        this.isusernameFocused = false
        this.isusernameEmpty = true
      }
      else if (this.password == '') {
        this.isPasswordFocused = false
        this.isPasswordEmpty = true
      }
      else {
        try {
          this.loading = true
          let formData = {
            email: this.username,
            password: this.password
          }
          await this.$store.dispatch('UserLogin', formData)
          this.$router.push('/stampVendorDashboard');
        }
        catch (e) {
          this.loading = false
          if(e.response.status == 422) {
            this.error = true
            this.error_msg = e.response.data.message;
            this.logout_btn = false;
            
          }
          if(e.response.status == 302) {
            this.error = true;
            this.error_msg = e.response.data.message;
            this.logout_btn = true;
          }
          if(e.response.status == 401) {
            this.error = true
            this.error_msg = e.response.data.message;
            this.logout_btn = false;
          }
        }
      }
    },

    async UserLogoutStep() {
      try {
        this.loading = true
        let formData = {
            username: this.username,
          }
        await this.$store.dispatch('UserLogoutStep',formData)
        this.$router.push('/stampVendorlogin');
        this.error = false;
        this.error_msg = '';
        this.logout_btn = false;
        this.loading = false
      }
      catch (e) {
        this.loading = false
        console.log(e)
      }
    },
  },
  created() {

  },
  metaInfo: {
  title: "Government of Punjab-eStamping Vendor Portal"
  }
}
</script>
<style scoped>
.Login-heading {
  font-family: 'MyFont', sans-serif;
  color: black;
  font-size: 28px;
  text-align: center;
  margin: 30px 0px 10px 0px;
}

.login-form-title {
  background-color: #eee;
  text-align: center;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-family: 'MyFont', serif;
  font-size: 20px;
}

.btns {
  background-color: #81C784;
  color: rgba(255, 255, 255, .84);
  border-radius: 2px;
}

.btns:hover {
  background-color: #81C784;
  color: #ffffff;
}

.btns:active {
  background-color: #81C784;
  border: 1px solid skyblue;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
}

.border-success {
  border-color: #28a745;
  transition: border-color .2s ease-in-out;
}
</style>