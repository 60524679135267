<template>
  <div>
    <header-component></header-component>

    <div class="container mx-auto mb-5">
      <h3 class="text-center mt-5 mb-5" style="font-family: 'MyFont';">Stamp Vendor Management Portal</h3>
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-md-3 mb-3">
          <div class="row">
            <div class="col-md-6 col-6">
              <img src="img/icon-challan32A.png" alt="Image" class="img-fluid"
                onmouseover="this.src='img/icon-challan32A-hover.png'" onmouseout="this.src='img/icon-challan32A.png'">
            </div>
            <div class="col-md-6 col-6 mt-3">
              <h6 style="font-weight: bold;">Generate Challan 32-A</h6>
              <p style="font-size:13px">Generate Challan Form 32-A for Requesting Re-issuance of Smart Card</p>
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="row">
            <div class="col-md-6 col-6">
              <img src="img/icon-adheive-challan.png" alt="Image" class="img-fluid"
                onmouseover="this.src='img/icon-adhesive-challan-hover.png'"
                onmouseout="this.src='img/icon-adheive-challan.png'">
            </div>
            <div class="col-md-6 col-6 mt-3">
              <h6 style="font-weight: bold;">Generate Challan 32-A (Adhesive Stamp)</h6>
              <p style="font-size:13px">Generate Challan 32-A to buy Adhesive Stamps Through Treasury</p>
            </div>
          </div>
        </div>

        <div class="col-md-3 mb-3">
          <div class="row">
            <div class="col-md-6 col-6">
              <img src="img/icon-reprintChallan32A.png" alt="Image" class="img-fluid"
                onmouseover="this.src='img/icon-reprintChallan32A-hover.png'"
                onmouseout="this.src='img/icon-reprintChallan32A.png'">
            </div>
            <div class="col-md-6 col-6 mt-3">
              <h6 style="font-weight: bold;">Re-print Challan 32-A</h6>
              <p style="font-size:13px">Verify or Re-print existing Challan Form 32-A</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-md-3 mb-3">
          <div class="row">
            <div class="col-md-6 col-6">
              <img src="img/icon_low-denomination-stamp.png" alt="Image" class="img-fluid"
                onmouseover="this.src='img/icon_low-denomination-stamp-hover.png'"
                onmouseout="this.src='img/icon_low-denomination-stamp.png'">
            </div>
            <div class="col-md-6 col-6 mt-3">
              <h6 style="font-weight: bold;">Generate Challan 32-A (Low Denomination Stamps)</h6>
              <p style="font-size:13px">Generate Challan 32-A to buy Low Denomination Stamps</p>
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <router-link class="nav-link" to="/stampVendorPortal">
            <div class="row">
              <div class="col-md-6 col-6">
                <img src="img/icon_low-denomination-stamp-issuance.png" alt="Image" class="img-fluid"
                  onmouseover="this.src='img/icon_low-denomination-stamp-issuance-hover.png'"
                  onmouseout="this.src='img/icon_low-denomination-stamp-issuance.png'">
              </div>
              <div class="col-md-6 col-6 mt-3">
                <h6 style="font-weight: bold; font-size: 15px;">Low Denomination Stamp Issuance</h6>
                <p style="font-size:13px">Issue Low Denomination Stamp to Citizen</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-3 mb-3">
          <router-link class="nav-link" to="/stampVendorReports">
          <div class="row">
            <div class="col-md-6 col-6">
              <img src="img/icon_view-report.png" alt="Image" class="img-fluid"
                onmouseover="this.src='img/icon_view-report-hover.png'" onmouseout="this.src='img/icon_view-report.png'">
            </div>
            <div class="col-md-6 col-6 mt-3">
              <h6 style="font-weight: bold;">View Reports</h6>
              <p style="font-size:13px">View Issued Stamps and Stock Report</p>
            </div>
          </div>
        </router-link>
        </div>

      </div>
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-md-3 mb-3">
          <div class="row">
            <div class="col-md-6 col-6">
              <img src="img/icon_verify-challan.png" alt="Image" class="img-fluid"
                onmouseover="this.src='img/icon_verify-challan-hover.png'"
                onmouseout="this.src='img/icon_verify-challan.png'">
            </div>
            <div class="col-md-6 col-6 mt-3">
              <h6 style="font-weight: bold;">Verify/Re-print Issued Stamp</h6>
              <p style="font-size:13px">Verify or Re-print existing Issued Low Denomination Stamp</p>
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="row">
            <div class="col-md-6 col-6">
              <img src="img/icon_vendor-account.png" alt="Image" class="img-fluid"
                onmouseover="this.src='img/icon_vendor-account-hover.png'"
                onmouseout="this.src='img/icon_vendor-account.png'">
            </div>
            <div class="col-md-6 col-6 mt-3">
              <h6 style="font-weight: bold;">Verify Challan</h6>
              <p style="font-size:13px">Verify Challan for Low Denomination Stamp Serials</p>
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="row">
            <div class="col-md-6 col-6"></div>
            <div class="col-md-6 col-6">
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer-component></footer-component>

  </div>
</template>
<script>

export default {
  name: "Vendor-Dashboard",
  data() {
    return {

    }
  },
  metaInfo: {
  title: "Dashboard"
  },
  methods: {
    async UserLogout(){
            try{
                await this.$store.dispatch('UserLogout')
                this.$router.push('/');
            }
            catch(e){
                console.log(e)
            } 
        },
  },
  computed: {
        checkLogin() {
        return this.$store.getters.isLoggedIn
      }
    }


}
</script>