<template>
  <div>
    <header-component></header-component>
    <loader-component v-if="loading"></loader-component>
    <div class="container-fluid" style="background-color:#E7E7E7">
      <router-link class="nav-link" to="/stampVendorDashboard">Home</router-link>
    </div>
    <div class="container mx-auto mb-5" v-if="firstStep">
      <h3 class="text-center mt-5 mb-2" style="font-family: 'MyFont', serif;">Issue Stamps to Citizen</h3>
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-md-9 mb-2">
          <img src="img/1st.png" class="img-fluid" style="height: 90px; width: 1200px;"/>
        </div>
      </div>
      <div class="card border-0">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-9">
            <div class="card-header border-0">
              <h5 class="login-form-title" style="font-family: 'MyFont', serif;"><i
                  class="fa fa-user"></i>&nbsp;&nbsp;Applicant Through</h5>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-8">
              <div class="form-group">
                <label style="size: 15rem;" class="text-dark">
                  <input type="radio" value="self" v-model="selectedOption" name="type" id="radiob"> Self
                </label>&nbsp;
                <label class="text-dark">
                  <input type="radio" autocomplete="off" value="agent" v-model="selectedOption" name="type" id="radiob"> Agent
                </label>
              </div>
            </div>
            <div class="col-md-9">
              <div class="form-group"></div>
            </div>
          </div>
          <div v-if="selectedOption === 'agent'">
            <div class="row d-flex justify-content-center align-items-center">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="intial-label" for="agentName"
                    v-bind:class="{ 'lable_active': isagentNameFocused, 'text-danger': isagentNameEmpty }">Name</label>
                  <input type="text" autocomplete="off" id="agentName" class="form-control border-0 border-bottom rounded-0"
                    v-model="agentName"
                    v-bind:class="{ 'border-success': isagentNameFocused, 'border-danger': isagentNameEmpty }"
                    @focus="agentNameClick()" v-on:blur="agentNameClickout()">
                  <span v-if="isagentNameEmpty" style="font-size: 12px; color:#FF0000">Name is required</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="agentCNIC"
                    v-bind:class="{ 'lable_active': isagentCNICFocused, 'text-danger': isagentCNICEmpty }">Agent CNIC</label>
                  <input type="text" v-mask="'#####-#######-#'" placeholder="_____-_______-_" id="agentCNIC" maxlength="15" autocomplete="off"
                    class="form-control border-0 border-bottom rounded-0" v-model="agentCNIC"
                    v-bind:class="{ 'border-success': isagentCNICFocused, 'border-danger': isagentCNICEmpty }"
                    @focus="agentCNICClick()" v-on:blur="agentCNICClickout()">
                  <span v-if="isagentCNICEmpty" style="font-size: 12px; color:#FF0000">CNIC is required</span>
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-center align-items-center">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="agentContact"
                    v-bind:class="{ 'lable_active': isagentContactFocused, 'text-danger': isagentContactEmpty }">Agent
                    Contact</label>
                  <input type="text" v-mask="'####-#######'" placeholder="____-_______" id="agentContact" maxlength="12" autocomplete="off"
                    class="form-control border-0 border-bottom rounded-0" v-model="agentContact"
                    v-bind:class="{ 'border-success': isagentContactFocused, 'border-danger': isagentContactEmpty }"
                    @focus="agentContactClick()" v-on:blur="agentContactClickout()">
                  <span v-if="isagentContactEmpty" style="font-size: 12px; color:#FF0000">Contact is required</span>
                </div>
              </div>
              
              <div class="col-md-4">
                <div class="form-group">
                  <label class="intial-label" for="agentEmail"
                    v-bind:class="{ 'lable_active': isagentEmailFocused, 'text-danger': isagentEmailEmpty }">Agent Email</label>
                  <input type="text" autocomplete="off" id="agentEmail" class="form-control border-0 border-bottom rounded-0"
                    v-model="agentEmail"
                    v-bind:class="{ 'border-success': isagentEmailFocused, 'border-danger': isagentEmailEmpty }"
                    @focus="agentEmailClick()" v-on:blur="agentEmailClickout()">
                  <span v-if="isagentNameEmpty" style="font-size: 12px; color:#FF0000">Email is required</span>
                
                  <!-- <label class="intial-label" for="agentEmail"
                    v-bind:class="{ 'lable_active': isagentEmailFocused, 'text-danger':isagentEmailEmpty}">Agent Email</label>
                  <input type="text" id="agentEmail" autocomplete="off" 
                    class="form-control border-0 border-bottom rounded-0" v-model="agentEmail" 
                    v-bind:class="{ 'border-success':isagentEmailFocused, 'border-danger':isagentEmailEmpty}" 
                    @focus="agentEmailClick()" v-on:blur="agentEmailClickout()"> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card  border-0">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-9">
            <div class="card-header border-0">
              <h5 class="login-form-title" style="font-family: 'MyFont', serif;"><i
                  class="fa fa-user"></i>&nbsp;&nbsp;Applicant Information</h5>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-4">
              <div class="form-group">
                <label class="intial-label" for="name"
                  v-bind:class="{ 'lable_active': isnameFocused, 'text-danger': isnameEmpty }">Name</label>
                <input type="text" autocomplete="off" id="name" class="form-control border-0 border-bottom rounded-0" v-model="name"
                  v-bind:class="{ 'border-success': isnameFocused, 'border-danger': isnameEmpty }" @focus="usernameClick()"
                  v-on:blur="usernameClickout()">
                <span v-if="isnameEmpty" style="font-size: 12px; color:#FF0000">Name is required</span>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="cnic"
                  v-bind:class="{ 'lable_active': iscnicFocused, 'text-danger': iscnicEmpty }">CNIC</label>
                <input type="text" v-mask="'#####-#######-#'" placeholder="_____-_______-_" id="cnic" maxlength="15" autocomplete="off"
                  class="form-control border-0 border-bottom rounded-0" v-model="cnic"
                  v-bind:class="{ 'border-success': iscnicFocused, 'border-danger': iscnicEmpty }" @focus="cnicClick()"
                  v-on:blur="cnicClickout()">
                <span v-if="iscnicEmpty" style="font-size: 12px; color:#FF0000">CNIC is required</span>
              </div>
            </div>
          </div>
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-4 mt-3">
              <div class="form-group">
                <label>Relation</label>
                <select class="form-control border-0 border-bottom rounded-0" v-model="relation">
                  <option disabled value="">Select relation</option>
                  <option value="D/O">D/O</option>
                  <option value="F/O">F/O</option>
                  <option value="Guardian">Guardian</option>
                  <option value="H/O">H/O</option>
                  <option value="M/O">M/O</option>
                  <option value="Representative From">Representative From</option>
                  <option value="S/O">S/O</option>
                  <option value="W/O">W/O</option>
                  <option value="Widow Of">Widow Of</option>
                </select>
              </div>
            </div>
            <div class="col-md-4 mt-3">
              <div class="form-group">
                <label class="intial-label" for="relationName"
                  v-bind:class="{ 'lable_active': isrelationNameFocused, 'text-danger': isrelationNameEmpty }">Relation
                  Name</label>
                <input type="text" autocomplete="off" id="relationName" class="form-control border-0 border-bottom rounded-0"
                  v-model="relationName"
                  v-bind:class="{ 'border-success': isrelationNameFocused, 'border-danger': iscnicEmpty }"
                  @focus="relationNameClick()" v-on:blur="relationNameClickout()">
                <span v-if="isrelationNameEmpty" style="font-size: 12px; color:#FF0000">Relation Name is required</span>
              </div>
            </div>
          </div>
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-4">
              <div class="form-group">
                <label for="contact"
                  v-bind:class="{ 'lable_active': iscontactFocused, 'text-danger': iscontactEmpty }">Contact</label>
                <input type="text" v-mask="'####-#######'" placeholder="____-_______" id="contact" maxlength="12" autocomplete="off"
                  class="form-control border-0 border-bottom rounded-0" v-model="contact"
                  v-bind:class="{ 'border-success': iscontactFocused, 'border-danger': iscontactEmpty }"
                  @focus="contactClick()" v-on:blur="contactClickout()">
                <span v-if="iscontactEmpty" style="font-size: 12px; color:#FF0000">contact is required</span>
              </div>
            </div>
            <div class="col-md-4 mt-2">
              <div class="form-group">
                <label class="intial-label" for="email" v-bind:class="{ 'lable_active': isemailFocused }">Email</label>
                <input type="text" autocomplete="off" id="email" class="form-control border-0 border-bottom rounded-0" v-model="email"
                  @focus="emailClick()" v-on:blur="emailClickout()">
              </div>
            </div>
          </div>
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-4">
              <div class="form-group">
                <label class="intial-label" for="address"
                  v-bind:class="{ 'lable_active': isaddressFocused, 'text-danger': isaddressEmpty }">Address</label>
                <input type="text" autocomplete="off" id="address" class="form-control border-0 border-bottom rounded-0" v-model="address"
                  v-bind:class="{ 'border-success': isaddressFocused, 'border-danger': isaddressEmpty }"
                  @focus="addressClick()" v-on:blur="addressClickout()">
                <span v-if="isaddressEmpty" style="font-size: 12px; color:#FF0000">address is required</span>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card  border-0">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-9">
            <div class="card-header border-0">
              <h5 class="login-form-title" style="font-family: 'MyFont', serif;"><i
                  class="fa fa-users"></i>&nbsp;&nbsp;Stamp Details</h5>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-4">
              <div class="form-group">
                <label for="purpose"
                  v-bind:class="{ 'lable_active': ispurposeFocused, 'text-danger': ispurposeEmpty }">Purpose</label>
                <select class="form-control border-0 border-bottom rounded-0" v-model="purpose" @change="purposeChange()">
                  <option value="">Select Purpose</option>
                  <option value="AFFIDAVIT - 4">AFFIDAVIT - 4</option>
                  <option value="PARTNERSHIP - 46(a)">PARTNERSHIP - 46(a)</option>
                  <option value="ADMINISTRATION BOND - 2(b)">ADMINISTRATION BOND - 2(b)</option>
                  <option value="ADOPTION DEED - 3">ADOPTION DEED - 3</option>
                  <option value="AGREEMENT OR MEMORANDUM OF AN AGREEMENT - 5(ccc)">AGREEMENT OR MEMORANDUM OF AN AGREEMENT
                    - 5(ccc)</option>
                  <option value="AGREEMENT RELATING TO DEPOSIT OF TITLE DEEDS, PAWN OR PLEDGE - 6(a)(i)">AGREEMENT RELATING TO DEPOSIT OF TITLE DEEDS, 
                    PAWN OR PLEDGE - 6(a)(i)</option>
                  <option value="CERTIFICATE OR OTHER DOCUMENT - 19">CERTIFICATE OR OTHER DOCUMENT - 19</option>
                  <option value="PROXY - 52">PROXY - 52</option>
                  <option value="SECURITY BOND OR MORTGAGE DEED - 57(b)">SECURITY BOND OR MORTGAGE DEED - 57(b)</option>
                </select>
                <span v-if="ispurposeEmpty" style="font-size: 12px; color:#FF0000">Purpose is required</span>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="denomination">Denomination</label>
                <input type="text" id="denomination" class="form-control border-0 border-bottom rounded-0"
                  v-model="denomination" readonly>
              </div>
            </div>
          </div>
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-4">
              <div class="form-group form-check">
                <input type="checkbox" class="form-check-input" v-model="isChecked" @change="checkedBox" /> Others
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="relation">Denomination</label>
                <select class="form-control border-0 border-bottom rounded-0 " v-if="!isChecked" v-model="denomination">
                  <option value="">Select denomination</option>
                </select>
                <select class="form-control border-0 border-bottom rounded-0 " v-if="isChecked" v-model="denomination">
                  <option value="">Select denomination</option>
                  <option value="100" v-if ="purpose == 'AFFIDAVIT - 4' ">100</option>
                  <option value="200" v-if ="purpose !== ''">200</option>
                  <option value="300" v-if ="purpose != 'AFFIDAVIT - 4'  && purpose !== ''">300</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-4 mt-4">
              <div class="form-group">
                <label for="relation">Serial Number</label>
                <select class="form-control border-0 border-bottom rounded-0 " v-model="serial_no">
                  <option :value="serial_no">{{ serial_no }}</option>
                </select>
                <span v-if="purpose != ''" style="font-size:12px; color:red">Only top 4 serial number will be displayed from
                  the stock</span><br>
                <span v-if="isserial_noEmpty" style="font-size: 12px; color:#FF0000">Serial number is required</span>
              </div>
            </div>
            <div class="col-md-4 mt-2">
              <div class="form-group">
                <label>No. of Samples</label>
                <input type="text" id="no_samples" class="form-control border-0 border-bottom rounded-0"
                  v-model="no_samples" readonly />

              </div>
            </div>
          </div>
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-4 mt-4">
              <div class="form-group">
                <label for="issue_date">Date</label>
                <input type="datetime-local" v-model="dateTime" class="form-control">
              </div>
            </div>
            <div class="col-md-4 mt-2">

            </div>
          </div>
          <div class="row d-flex justify-content-center align-items-center mt-3">
            <div class="col-md-9">
              <div class="form-group">
                <textarea rows="8" cols="200" class="form-control" placeholder="Reason" v-model="reason"></textarea>
              </div>
              <span v-if="isreasonEmpty" style="font-size: 12px; color:#FF0000">Reason is required</span>
            </div>
          </div>
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-9">
              <div class="form-group">
                <button class="btn btn-success float-end mt-2" @click="tempSave()">Add</button>
              </div>
            </div>
            <div class="form-group ">
            </div>
          </div>
          <div class="card  border-0 mt-4" v-if="selected_stamp">
            <div class="row d-flex justify-content-center align-items-center">
              <div class="col-md-9">
                <div class="card-header border-0">
                  <h6 class="login-form-title" style="font-family: 'MyFont';">&nbsp;&nbsp;Selected Denominations</h6>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row d-flex justify-content-center align-items-center">
                <div class="col-md-9">
                  <table class="table table-bordered">
                    <thead>
                      <tr class="card-header">
                        <th>Sr.#</th>
                        <th>Serial Number</th>
                        <th>Denomination</th>
                        <th>Purpose</th>
                        <th>Reason</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>{{ serial_no }}</td>
                        <td>{{ denomination }}</td>
                        <td>{{ purpose }}</td>
                        <td>{{ reason }}</td>
                        <td><i class="fa fa-trash"></i></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="row d-flex justify-content-center align-items-center mt-3">
            <div class="col-md-9">
              <div class="form-group">
                <button class="btn btn-success float-end mt-2" @click="MoveStep2()">Next</button>
              </div>
            </div>
            <div class="form-group ">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mx-auto mb-5" v-if="secondStep">
      <h3 class="text-center mt-5 mb-5" style="font-family: 'Calibri', serif;">Issue Stamps to Citizen</h3>
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-md-9 mb-4">
          <img src="img/2nd.png" class="img-fluid" style="height:90px;" />
        </div>
      </div>
      <div class="card  border-0">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-9">
            <div class="card-header border-0">
              <h5 class="login-form-title" style="font-family: 'MyFont', serif;"><i
                  class="fa fa-users"></i>&nbsp;&nbsp;Total Payable Amount</h5>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-9">
              <p>
                <b>Total Payable Amount (Rs.):</b> <br>
                {{ denomination }}
              </p>
              <table class="table table-bordered">
                <thead>
                  <tr class="card-header">
                    <th>Sr.#</th>
                    <th>Serial Number</th>
                    <th>Denomination</th>
                    <th>Purpose</th>
                    <th>Reason</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>{{ serial_no }}</td>
                    <td>{{ denomination }}</td>
                    <td>{{ purpose }}</td>
                    <td>{{ reason }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="card  border-0">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-9">
            <div class="card-header border-0">
              <h5 class="login-form-title" style="font-family: 'MyFont', serif;"><i
                  class="fa fa-user"></i>&nbsp;&nbsp;Applicant Information</h5>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-9">
              <table class="table table-bordered">
                <thead>
                  <tr class="card-header">
                    <th>Name</th>
                    <th>CNIC</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ name }} {{ relation }} {{ relationName }}</td>
                    <td>{{ cnic }}</td>
                    <td><i class="fa fa-eye "></i></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-9">
              <button class="btn btn-success float-end text-uppercase" @click="moveStep3()">Save</button>
              <button class="btn btn-info float-end text-uppercase" style="margin-right: 10px;"
                @click="backStep1()">Back</button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="container mx-auto mb-5" v-if="thirdStep">
      <h3 class="text-center mt-5 mb-2" style="font-family: 'MyFont', serif;">Issue Stamps to Citizen</h3>
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-md-9 mb-4">
          <img src="img/3rd.png" class="img-fluid" style="height:90px;" />
        </div>
      </div>
      <div class="card  border-0">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-9">
            <div class="card-header border-0">
              <h5 class="login-form-title" style="font-family: 'MyFont', serif;"><i
                  class="fa fa-check"></i>&nbsp;&nbsp;Issue Stamps to Citizen</h5>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-9">
              <p>
                Stamps are Successfully Issued to the Citizen
              </p>
            </div>
          </div>
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-9">
              <button class="btn btn-success float-end text-uppercase" @click="moveStep4">print</button>
              <router-link class=" btn btn-success float-end text-uppercase" to="/stampvendorDashboard"
                style="margin-right: 10px;">go to home</router-link>
              <!-- <button class="btn btn-info float-end text-uppercase" style="margin-right: 10px;">go to home</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer-component></footer-component>
  </div>
</template>
<script>
export default {
  metaInfo: {
  title: "Issue Stamp"
  },
  name: "Vendor-Dashboard",
  data() {
    return {
      isChecked: false,
      loading: false,
      firstStep: true,
      secondStep: false,
      thirdStep: false,
      fourthStep: false,
      validMsg: false,
      formFill: false,
      selectedOption: 'self',
      name: '',
      isnameFocused: false,
      isnameEmpty: false,
      cnic: '',
      iscnicFocused: false,
      iscnicEmpty: false,
      relationName: '',
      isrelationNameFocused: false,
      isrelationNameEmpty: false,
      contact: '',
      iscontactFocused: false,
      iscontactEmpty: false,
      email: '',
      isemailFocused: false,
      isemailEmpty: false,
      address: '',
      isaddressFocused: false,
      isaddressEmpty: false,
      relation: '',
      purpose: '',
      ispurposeFocused: false,
      ispurposeEmpty: false,
      denomination: '',
      denomination_drop_down: 'Select denomination',
      no_samples: '',
      agentName: '',
      isagentNameFocused: false,
      isagentNameEmpty: false,
      agentCNIC: '',
      isagentCNICFocused: false,
      isagentCNICEmpty: false,
      agentContact: '',
      isagentContactFocused: false,
      isagentContactEmpty: false,
      agentEmail: '',
      isagentEmailFocused: false,
      isagentEmailEmpty: false,
      serial_no: 'Select serial number',
      isserial_noEmpty: false,
      reason: '',
      isreasonEmpty: false,
      selected_stamp: false,
      isstampSerialNumberFocused: false,
      isstampSerialNumberEmpty: false,
      stampSerialNumber: '',
      dateTime: '',
      dropdown2:'',
      amount_in_words:''
    }
  },
  methods: {
    usernameClick() {
      this.isnameFocused = true
    },
    usernameClickout() {
      if (this.name.trim() === '') {
        this.isnameFocused = false
        this.isnameEmpty = true
      }
      else {
        this.isnameEmpty = false
      }
    },
    cnicClick() {
      this.iscnicFocused = true
    },
    cnicClickout() {
      if (this.cnic.trim() === '') {
        this.iscnicFocused = false
        this.iscnicEmpty = true
      }
      else {
        this.iscnicEmpty = false
      }
    },
    relationNameClick() {
      this.isrelationNameFocused = true

    },
    relationNameClickout() {
      if (this.relationName.trim() === '') {
        this.isrelationNameFocused = false
        this.isrelationNameEmpty = true
      }
      else {
        this.isrelationNameEmpty = false
      }
    },

    contactClick() {
      this.iscontactFocused = true

    },
    contactClickout() {
      if (this.contact.trim() === '') {
        this.iscontactFocused = false
        this.iscontactEmpty = true
      }
      else {
        this.iscontactEmpty = false
      }
    },

    emailClick() {
      this.isemailFocused = true

    },
    emailClickout() {
      if (this.email.trim() === '') {
        this.isemailFocused = false
        this.isemailEmpty = true
      }
      else {
        this.isemailEmpty = false
      }
    },

    addressClick() {
      this.isaddressFocused = true

    },
    addressClickout() {
      if (this.address.trim() === '') {
        this.isaddressFocused = false
        this.isaddressEmpty = true
      }
      else {
        this.isaddressEmpty = false
      }
    },
    purposeChange() {
      if (this.purpose.trim() === '') {
        this.denomination = ''
        this.denomination_drop_down = 'Select denomination'
        this.serial_no = 'Select serial number',
        this.no_samples = ''
        this.ispurposeEmpty = true
        this.isChecked = false
      }
      else if (this.isChecked) {
        this.denomination = ''
        
      }
      else {
        if (this.purpose == "AFFIDAVIT - 4") {
          this.denomination = 300
          this.amount_in_words = "Three Hundred Rupees Only"
        }
        else{
          this.denomination = 100
          this.amount_in_words = "One Hundred Rupees Only"
        }
        this.no_samples = 1
        this.ispurposeEmpty = false
        const length = 16;
        const characters = 'ABCDEF012345678911121314151617181920abcdef';
        let result = '';
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          result += characters.charAt(randomIndex);
        }
        this.serial_no = 'PB-RYK-' + result.toUpperCase()
      }
    },
    agentNameClick() {
      this.isagentNameFocused = true
    },
    agentNameClickout() {
      if (this.agentName.trim() === '') {
        this.isagentNameFocused = false
        this.isagentNameEmpty = true
      }
      else {
        this.isagentNameEmpty = false
      }
    },
    agentCNICClick() {
      this.isagentCNICFocused = true
    },
    agentCNICClickout() {
      if (this.agentCNIC.trim() === '') {
        this.isagentCNICFocused = false
        this.isagentCNICEmpty = true
      }
      else {
        this.isagentCNICEmpty = false
      }
    },
    agentContactClick() {
      this.isagentContactFocused = true
    },
    agentContactClickout() {
      if (this.agentContact.trim() === '') {
        this.isagentContactFocused = false
        this.isagentContactEmpty = true
      }
      else {
        this.isagentContactEmpty = false
      }
    },
    agentEmailClick() {
      this.isagentEmailFocused = true
    },
    agentEmailClickout() {
      if (this.agentEmail.trim() === '') {
        this.isagentEmailFocused = false
        this.isagentEmailEmpty = true
      }
      else {
        this.isagentEmailEmpty = false
      }
    },
    checkedBox() {
      if (this.isChecked) {
        this.denomination = '';
      }
      else {
        if (this.purpose == "AFFIDAVIT - 4") {
          this.denomination = 300
          this.denomination_drop_down = 'Select denomination'
          this.amount_in_words = "Three Hundred Rupees Only"
        }
        else{
          this.denomination = 100
          this.denomination_drop_down = 'Select denomination'
          this.amount_in_words = "One Hundred Rupees Only"
        }
      }

    },
    tempSave() {
      this.loader = true;
      if (this.selectedOption === '') {
        alert('Applicant Through');
        return
      }
      if (this.isChecked) {
        
        if(this.denomination_drop_down.selectedOption)
        {
          this.denomination
        }
        else{
          this.denomination
        }
      }
      if (this.selectedOption === 'agent') {
        if (this.agentName == '') {
          this.isagentNameEmpty = true
        }
        else if (this.agentCNIC == '') {
          this.isagentCNICEmpty = true
        }
        else if (this.agentContact == '') {
          this.isagentContactEmpty = true
        }

        else if (this.name == '') {
          this.isnameEmpty = true
        }
        else if (this.cnic == '') {
          this.iscnicEmpty = true
        }
        else if (this.relationName == '') {
          this.isrelationNameEmpty = true
        }
        else if (this.contact == '') {
          this.iscontactEmpty = true
        }
        else if (this.address == '') {
          this.isaddressEmpty = true
        }
        else if (this.purpose == '') {
          this.ispurposeEmpty = true
        }
        else if (this.reason == '') {
          this.isreasonEmpty = true
        }
        else if (this.serial_no == '') {
          this.isserial_noEmpty = true
        }
        else {
          this.loader = false
          this.formFill = true
          this.selected_stamp = true
        }
      }
      else {
        if (this.name == '') {
          this.isnameEmpty = true
        }
        else if (this.cnic == '') {
          this.iscnicEmpty = true
        }
        else if (this.relationName == '') {
          this.isrelationNameEmpty = true
        }
        else if (this.contact == '') {
          this.iscontactEmpty = true
        }
        else if (this.address == '') {
          this.isaddressEmpty = true
        }
        else if (this.purpose == '') {
          this.ispurposeEmpty = true
        }
        else if (this.reason == '') {
          this.isreasonEmpty = true
        }
        else if (this.serial_no == '') {
          this.isserial_noEmpty = true
        }
        else {
          this.loader = false
          this.formFill = true
          this.selected_stamp = true
        }
      }
    },
    MoveStep2() {
      this.loading = true
      if (this.formFill == true) {
        this.firstStep = false,
          this.secondStep = true
        this.loading = false
      }
      else {
        alert('fill form')
        this.loading = false
      }
    }
    ,
    backStep1() {
      this.firstStep = true
      this.secondStep = false
      this.selected_stamp = false
      this.formFill = false
      this.agentName = ''
      this.agentCNIC = ''
      this.agentContact = ''
      this.agentEmail = ''
      this.name = ''
      this.cnic = ''
      this.relation = ''
      this.relationName = ''
      this.contact = ''
      this.email = ''
      this.address = ''
      this.purpose = ''
      this.denomination = ''
      this.serial_no = ''
      this.no_samples = ''
      this.reason = ''
    },
    async moveStep3() {
      //here query
      this.loading = true
      try {
        if (this.isChecked) {
          if(this.denomination_drop_down.selectedOption){
            this.denomination
          }
          else{
            this.denomination
          }
        }
        if (this.selectedOption === 'agent') {
          this.selectedOption = 'Agent'
        }
        let formData = {
          agent_name: this.agentName,
          agent_cnic: this.agentCNIC,
          agent_contact: this.agentContact,
          agent_email: this.agentEmail,
          name: this.name,
          cnic: this.cnic,
          relation: this.relation,
          relation_name: this.relationName,
          contact: this.contact,
          email: this.email,
          address: this.address,
          purpose: this.purpose,
          denomination: this.denomination,
          serial_no: this.serial_no,
          no_samples: this.no_samples,
          reason: this.reason,
          issue_date: this.dateTime,
          through: this.selectedOption
        }

        await this.$store.dispatch('SaveStamp', formData)
        this.secondStep = false
        this.thirdStep = true
        this.loading = false
      }
      catch (e) {
        this.loading = false
        console.log(e)
      }
    },
    moveStep4() {
      this.$router.push('/stampVendorSearch');
    },
    moveStep5() {
      this.fourthStep = false
      this.fifthStep = true
    },
    stampSerialNumberClick() {
      this.isstampSerialNumberFocused = true
    },
    stampSerialNumberClickout() {
      if (this.stampSerialNumber.trim() === '') {
        this.isstampSerialNumberFocused = false
        this.isstampSerialNumberEmpty = true
      }
      else {
        this.isstampSerialNumberEmpty = false
      }
    },
    searchStamp() {
      if (this.stampSerialNumber.trim() === '') {
        this.isstampSerialNumberFocused = false
        this.isstampSerialNumberEmpty = true
      }
      else {
        this.isstampSerialNumberEmpty = false
        this.validMsg = true
        // here query
      }
    },
    async UserLogout() {
      try {
        await this.$store.dispatch('UserLogout')
        this.$router.push('/');
      }
      catch (e) {
        console.log(e)
      }
    },

  },
  mounted() {
    // Get the current date and time
    const now = new Date();

    // Format the date and time string
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const dateTimeString = `${year}-${month}-${day}T${hours}:${minutes}`;

    // Set the default value for the input field
    this.dateTime = dateTimeString;
  },
  computed: {
    checkLogin() {
      return this.$store.getters.isLoggedIn
    }
  }
}
</script>
<style scoped>.intial-label {
  font-size: 1rem;
  transform: translateY(1.8rem);
}

.lable_active {
  transform: translateY(-0.01rem);
  transition: all 0.3s ease;
  color: #28a745;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
}

.border-success {
  border-color: #28a745;
  transition: border-color .3s ease-in-out;
}

label {
  color: #aaa;
}
#radiob {
  height: 1em; 
  width: 1em; 
  accent-color: rgba(88, 150, 96, 0.955); 
  border: 2px solid;
}</style>
